import * as React from 'react'
import Layout from '../components/layout'
import HeroPage from '../components/heroPage'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const PrezziOld = () => {
    return (
        <Layout>
            <SEO title="Quanto costa aprire un call center: ecco i prezzi del CRM Thelgo" description="Scopri quanto costa aprire un call center: prezzi per il software Thelgo, le chiamate telefoniche Voip, il cloud e l'hardware." />
            <HeroPage tag="Cloud Platform" title="La rivoluzione passa dal Cloud" subtitle="Scopri i vantaggi dell'offerta">
                Thelgo offre un servizio a consumo che permette di utilizzare il software in diverse soluzioni senza nessun investimento iniziale ma semplicemente con un costo mensile. Un servizio di Cloud Computing che prevede l’utilizzo del software per telemarketing e teleselling Thelgo attraverso un server web (SaaS – sofware as a Service) in cloud computing.
            </HeroPage>

            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="text-center mb-8">
                        <span className="tag">Prezzi</span>
                        <h3 className="text-3xl text-gray-800">Tre opzioni per esigenze diverse</h3>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
                        <div className="text-center flex flex-col items-center space-y-2">
                            <StaticImage src="../images/basic_large.png" layout="fixed" width={200} alt="Thelgo Basic" placeholder="blurred" className="mb-2" />
                            <h4 className="text-xl text-persian-green-500">Thelgo BASIC</h4>
                            <span className="block font-bold">Software</span>
                            <p>La versione base del software Thelgo, con le tutte le funzionalità essenziali per il suo utilizzo in ogni ambito.</p>
                        </div>

                        <div className="text-center flex flex-col items-center space-y-2">
                            <StaticImage src="../images/pro_large.png" layout="fixed" width={200} alt="Thelgo Pro" placeholder="blurred" className="mb-2" />
                            <h4 className="text-xl text-persian-green-500">Thelgo PRO</h4>
                            <span className="block font-bold">Software + PBX</span>
                            <p>Thelgo PRO è pensato per chi ha bisogno di una soluzione che comprenda anche il centralino PBX virtuale.</p>
                        </div>

                        <div className="text-center flex flex-col items-center space-y-2">
                            <StaticImage src="../images/full_large.png" layout="fixed" width={200} alt="Thelgo Full" placeholder="blurred" className="mb-2" />
                            <h4 className="text-xl text-persian-green-500">Thelgo FULL</h4>
                            <span className="block font-bold">Software + PBX + Predictive Dialing</span>
                            <p>La versione completa di Thelgo che oltre a fornire il centralino PBX virtuale, aggiunge anche il modulo per il Predictive Dialing, un must per il Call Center.</p>
                        </div>
                    </div>

                    <div className="table price-table mt-12">
                        <table class="table-auto border border-gray-300 w-full">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Basic</th>
                                    <th>Pro</th>
                                    <th>Full</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Fino a 4 postazioni</td>
                                    <td>€ 20,00*</td>
                                    <td>€ 35,00*</td>
                                    <td>€ 45,00*</td>
                                </tr>
                                <tr class="bg-emerald-200">
                                    <td>Fino a 9 postazioni</td>
                                    <td>€ 18,00*</td>
                                    <td>€ 25,00*</td>
                                    <td>€ 35,00*</td>
                                </tr>
                                <tr>
                                    <td>Fino a 20 postazioni</td>
                                    <td>€ 17,00*</td>
                                    <td>€ 23,00*</td>
                                    <td>€ 30,00*</td>
                                </tr>
                                <tr>
                                    <td>Fino a 30 postazioni</td>
                                    <td>€ 14,00*</td>
                                    <td>€ 21,00*</td>
                                    <td>€ 25,00*</td>
                                </tr>
                                <tr>
                                    <td>Oltre 30 postazioni</td>
                                    <td>€ 12,00*</td>
                                    <td>€ 18,00*</td>
                                    <td>€ 23,00*</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="my-8 flex flex-wrap gap-8">
                            <div className="flex-1">
                            <p className="mb-2"><b>Per postazioni intendiamo le sessioni attive in contemporanea</b> (ad esempio un call center con 20 operatori suddivisi in 2 turni vengono conteggiati come 10 operatori). Gli utenti Amministratori e gli utenti Agenti <b>non vengono conteggiati</b> dal punto di vista economico.</p>
                            <p className="text-red-500 text-sm mb-8">* Il prezzo corrisponde al canone mensile PER POSTAZIONE con pagamento BIMESTRALE anticipato e IVA esclusa.</p>
                            <p className="text-2xl text-persian-green-500 font-bold animate-bounce">Sconto del 20% se acquisti un anno di abbonamento</p>
                            </div>
                            <div className="w-full lg:w-3/12 lg:text-right">
                           <Link to="/demo" className="button-lg bg-persian-green-500 inline-flex text-white hover:bg-persian-green-700 transition-colors">Richiedi una demo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PrezziOld